$white: rgba(255, 255, 255, 0.45);
$lightGray: rgba(245, 245, 245, 0.45);
$mediumGray: #dfdfdf;
$darkerGray: #c3c3c3;
$darkGray: #4e4e4e;
$blue: #3f51b5;
$red: #ff4081;
$green: #4caf50;
$breathingText: #959595;

@mixin center-position-fixed {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin top-position-fixed {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);
}

@mixin bottom-position-fixed {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 15px;
}

@mixin center-position-fixed {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}