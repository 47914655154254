@import './_variables';

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, sans-serif;
    background-color: #fff;
}

.main-container {
    display: grid; 
    max-width: 1440px; 
    min-height: 800px;
    grid-template-columns: auto 1fr;
    margin: 0 auto; 
    gap: 10px;
    margin-top: 50px;
    align-items: start;
    justify-items: start;
}

@media (max-width: 600px) {
    .main-container {
        margin-left: 20px;
    }
}

.bottom-position-fixed {
    @include bottom-position-fixed;
}

.center-position-fixed {
    @include center-position-fixed;
}

button {
    max-width: 200px;
}

.no-overflow {
    overflow: hidden;
}

.spinner {
    height: 60px;
    width: 60px;
    animation: rotation .6s infinite linear;
    border-left: 6px solid rgba(0, 174, 239, .15);
    border-right: 6px solid rgba(0, 174, 239, .15);
    border-bottom: 6px solid rgba(0, 174, 239, .15);
    border-top: 6px solid rgba(0, 174, 239, .8);
    border-radius: 100%;
}

.loading {
    color: #fff;
    font-size: 20px;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

// .mat-mdc-tab {
//     flex-basis: 0 !important;
//     max-width: none !important;
//     min-width: 0 !important;
//     flex-grow: 1 !important;
//     background-color: $lightGray !important;
//     margin-right: 1px !important;
// }

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: $white;
}

// Custom overlayPanelClass for MAT_SELECT_CONFIG
.mat-select-overlay-pane {
    width: 40vh !important;
}

// Select text
mat-label,
mat-option.mat-mdc-option .mdc-list-item__primary-text,
.mat-mdc-select-min-line {
    font-size: 0.9em;
}

// .mat-mdc-select-arrow svg {
//     width: 35px;
//     height: 35px;
// }

.mat-tab-group {
    flex-direction: row !important;
}

.mat-tab-labels {
    flex-direction: column !important;
}

.mat-tab-label-active {
    border-right: 2px solid red !important;
}

.mat-ink-bar {
    display: none;
}

/* Targeting the mat-form-field for the timer input specifically */
.timer-input {
    .mat-mdc-form-field-infix {
        width: 75px;
        background: $lightGray;
    }

    .mdc-text-field__input {
        font-size: 18px !important;
        padding: 8px;
        width: 50px;
    }
}